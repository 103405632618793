<template lang="pug">
  .swiper-wrapper
    .swiper-slide.news__item(
      v-for="news of newsList"
      :key="news.id"
    )
      div(@click="navigateToDetail(news.id, news.url)")
        img(
          v-if="news.image"
          :src="getImagePath(news.image)"
          :alt="news.title"
        )
        h4(v-if="news.title") {{ news.title }}
        p(v-if="news.description") {{ news.description }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'NewsCard',
  computed: {
    ...mapState({
      newsList: state => state.directus.newsList
    })
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    ...mapActions(['fetchNews']),
    navigateToDetail (newsId, newsUrl) {
      this.$router.push({ name: 'NewsDetail', params: { slug: newsUrl } })
    },
    getImagePath (image) {
      return `${ process.env.VUE_APP_API }/assets/${image}`
    }
  }
}
</script>
