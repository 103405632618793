<template lang="pug">
  .news-page
    section.hero
      .hero__container
        h1.hero__title.title
          | Наші
          |
          span новини
        p.hero__desc Слідкуйте за актуальною інформацією на нашому сайті.
        h2.hero__sub-title Новини

    section.news
      .news__container
        .news__slider.swiper(ref="swiper")
          NewsCard
          .news__actions
            .swiper-button-prev
            .swiper-pagination
            .swiper-button-next
</template>

<script>
// swiper
import Swiper, { Navigation, Grid, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// NewsCard
import NewsCard from '@/components/NewsCard'
export default {
  name: 'News',
  components: { NewsCard },
  mounted () {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Grid, Pagination],
      slidesPerView: 1,
      slidesPerColumn: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      //loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      breakpoints: {
        250: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 2,
          grid: {
            rows: 2,
            fill: "row"
          },
          spaceBetween: 10
        },
        420: {
          slidesPerView: 2,
          slidesPerColumn: 2,
          slidesPerGroup: 2,
          grid: {
            rows: 1,
            fill: "row"
          },
          spaceBetween: 10
        },
        480: {
          slidesPerView: 2,
          slidesPerColumn: 2,
          slidesPerGroup: 2,
          grid: {
            rows: 2,
            fill: "row"
          },
          spaceBetween: 15
        },
        992: {
          slidesPerView: 3,
          slidesPerColumn: 3,
          slidesPerGroup: 3,
          grid: {
            rows: 2,
            fill: "row"
          },
          spaceBetween: 15
        }
      }
    })
  }
}
</script>
